import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import UploadForm from "./UploadForm";
import { AuthProvider } from "@nplan-io/with-gcip-auth";
import AssetProvider from "@nplan-io/asset-provider";
import { AUTH_DOMAIN, FIREBASE_API_KEY } from "./config";
import { ThemeProvider, nPlanLight } from "@nplan-io/theme";
import WithAnalytics from "@nplan-io/with-analytics";
import * as serviceWorker from "./serviceWorker";

const theme = {
  ...nPlanLight,
  colours: {
    ...nPlanLight.colours,
    primary: "#172B38",
    secondary: "#BDD2E4",
    clear: "#648A9E"
  },
  objects: {
    ...nPlanLight.objects,
    secondary: { background: "#BDD2E4", text: "#172B38" },
    default: { background: "#E1E2E3", text: "#757575" },
    clear: { background: "#F6F8FA", text: "#648A9E" },
    table: {
      borderRadius: "0px",
      headerBackground: "#fff",
      footerBackground: "#fff",
      borderColour: "#ccc"
    },
    widget: { textMuted: "#ccc" }
  }
};

ReactDOM.render(
  <AuthProvider
    authPageUrl={AUTH_DOMAIN}
    accessTokenStorageKey={"API_KEY"}
    callbackUrl={window.location.origin}
    firebaseApiKey={FIREBASE_API_KEY}
  >
    <ThemeProvider theme={theme}>
      <AssetProvider>
        <WithAnalytics>
          <UploadForm />
        </WithAnalytics>
      </AssetProvider>
    </ThemeProvider>
  </AuthProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
