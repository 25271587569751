/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import WithData, { useData } from "@nplan-io/with-data";
import { useAuth } from "@nplan-io/with-gcip-auth";
import Uploader from "@nplan-io/uploader";
import Spinner from "@nplan-io/spinner";
import { gql } from "apollo-boost";
import { WithConfig } from "@nplan-io/config";

const GET_PROJECTS = gql`
  query getProjects {
    getProjects {
      name
      uid
      country
      region
      created
      country
      region
      value
      projectType
      plannedDate
      description
      uiConfig
      secondaryTargets
      client
      language
      usersWithAccess {
        email
        role
      }
      programmes {
        uid
        name
        status
        simulationDetails
        client
        processingErrors {
          data
          errorType
        }
      }
    }
  }
`;

const UploadForm = () => {
  const { useQuery, client } = useData();
  const { loading, data } = useQuery(GET_PROJECTS, {
    fetchPolicy: "cache-and-network"
  });

  if (loading)
    return (
      <div
        css={theme => css`
          display: flex;
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          background-color: ${theme.colours.primary};
        `}
      >
        <Spinner size="100px" />
      </div>
    );
  return (
    <div
      css={theme => css`
        background-color: ${theme.colours.primary};
        height: 100vh;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        `}
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          <img
            src={require("../Assets/nPlan_logo_white.png")}
            alt="nPlan Logo"
            width="200px"
            css={css`
              padding: 50px 0px 0px 0px;
              display: block;
              margin: 0 auto;
            `}
          />
        </div>
        <WithConfig client={client}>
          <Uploader projects={data} />
        </WithConfig>
      </div>
    </div>
  );
};

const UploadFormWithData = () => {
  const { loading } = useAuth();

  if (loading)
    return (
      <div
        css={theme => css`
          background-color: ${theme.colours.primary};
          min-height: 100vh;
          padding-top: 200px;
        `}
      >
        <Spinner size="50px" />
      </div>
    );
  return (
    <WithData API_URL={"https://canary.nplan.io/graphql"} type="graphql">
      <UploadForm />
    </WithData>
  );
};

export default UploadFormWithData;
